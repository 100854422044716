export const events: IEvent[] = [
    {
        title: "2024 Portage Open",
        tier: "C",
        date: "Saturday, August 17",
        location: "Island Park",
        td: "Cody Buhler",
        url: "https://www.discgolfscene.com/tournaments/2024_Portage_Open_2024/register",
    },
    {
        title: "2024 DGM Fall Classic",
        tier: "C",
        date: "Sat-Sun, September 21-22",
        location: "Kilcona Lakes",
        td: "Nick Briere, Jessica Benoit",
        url: "https://www.discgolfscene.com/tournaments/2024_DGM_Fall_Classic_2024/registration",
    },
    {
        title: "2024 Manitoba Disc Golf Tour",
        tier: "XC",
        date: "Sat-Sun, October 5-6",
        location: "Stony Mountain Quarry",
        td: "Xavier Tomanek",
        url: "https://www.discgolfscene.com/tournaments/2024_Manitoba_Disc_Golf_Tour_2024/registration",
    },
]